import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

function Fame(props) {
	let [respond, setRespond] = useState([]);
	let [hidden, setHidden] = useState(false);
	let [clips, setClips] = useState([]);
	const apiUrl = process.env.REACT_APP_SERVER_URL;

	let userData = {
		username: props.userData.username,
		id: props.userData.id,
		autherized: props.userData.autherized,
		nickname: props.userData.nickname,
	};
	useEffect(() => {
		props.transferData(userData);
	}, [1]);

	async function requestServer() {
		let result = await fetch(apiUrl + '/halloffame', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setRespond(data.playerResult);
			});
	}
	useEffect(() => {
		requestServer();
	}, []);

	let filterRespond = respond.sort((a, b) => b.kills - a.kills);
	const leaderBoard = filterRespond.map((player, i) => {
		return (
			<tr className="Leaderboard-BodyRow" key={i}>
				<td className="Leaderboard-BodyItem" id={'Number' + i}>
					#{i + 1}
				</td>
				<td className="Leaderboard-BodyItem" id={'Number' + i}>
					{player.nick}
				</td>
				<td className="Leaderboard-BodyItem" id={'Number' + i}>
					{player.kills}
				</td>
				<td className="Leaderboard-BodyItem" id={'Number' + i}>
					<button id={player.id} value={player.id} onClick={clipRequest}>
						Hier
					</button>
				</td>
			</tr>
		);
	});
	async function clipRequest(event) {
		try {
			let result = await fetch(apiUrl + '/player/pk/clips', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ id: event.target.value }),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					setClips(data.data);
					setHidden(true);
				});
		} catch (err) {
			console.log(err);
		}
	}
	const playerClips = clips.map((data, i) => {
		return (
			<div style={{ marginTop: '3rem' }}>
				<h3>Clips Libary from : {data.nick} </h3>
				<p>Kill amount : {data.killamount}</p>
				<div className="Web-Player">
					<ReactPlayer className="react-player" url={data.link} />
				</div>
				<div className="Mobile-Player">
					<ReactPlayer
						width="320px"
						height="240px"
						className="react-player"
						url={data.link}
					/>
				</div>
			</div>
		);
	});

	return (
		<>
			<div style={{ marginTop: '7rem' }} className="Content">
				<div className="Flex-Container">
					<div hidden={hidden} className="Leaderboard-Container">
						<section className="Leaderboard-Background">
							<h1 className="Leaderboard-Title">{props.title}</h1>
							<table className="Leaderboard">
								<thead className="Leaderboard-Head">
									<tr className="Leaderboard-Row">
										<th className="Leaderboard-HeadItem">Rank</th>
										<th className="Leaderboard-HeadItem">Nickname</th>
										<th className="Leaderboard-HeadItem">Kills</th>
										<th className="Leaderboard-HeadItem">Clips</th>
									</tr>
								</thead>
								<tbody className="Leaderboard-Body">{leaderBoard}</tbody>
							</table>
						</section>
					</div>
					<section>{playerClips}</section>
				</div>
			</div>
		</>
	);
}

export default Fame;
